import { graphql } from "gatsby"
import {
  ContentfulRichTextGatsbyReference,
  renderRichText,
  RenderRichTextData,
} from "gatsby-source-contentful/rich-text"
import React from "react"
import styled from "styled-components"
import {
  ButtonLink,
  Column,
  Container,
  Head,
  Heading,
  Row,
  SecondarySection,
  Spacer,
} from "../../components/components"

interface Props {
  data: {
    jobPost: {
      title: string
      excerpt: string
      content: RenderRichTextData<ContentfulRichTextGatsbyReference>
      slug: string
      createdAt: string
    }
  }
}

const JobPostTemplate: React.FC<Props> = ({ data }) => {
  return (
    <>
      <Head
        overwrite={{
          title: data.jobPost.title,
          description: data.jobPost.excerpt,
        }}
      />
      <Container style={{ paddingTop: "3rem" }}>
        <Row style={{ marginBottom: "2rem" }}>
          <Column>
            <Heading tag="h1">{data.jobPost.title}</Heading>
          </Column>
        </Row>

        <SecondarySection>
          <Container>
            <Row>
              <Column>
                <ArticleSection>
                  {renderRichText(data.jobPost.content)}
                </ArticleSection>
              </Column>
            </Row>

            <ButtonLink
              style={{ marginTop: "3rem", width: "unset" }}
              to={"/kontakt"}
              htmlLinkHref={"mailto:info@incoqnito.io"}
            >
              Jetzt bewerben
            </ButtonLink>
          </Container>
        </SecondarySection>
      </Container>

      <Spacer gutter={80} />
    </>
  )
}

export default JobPostTemplate

const ArticleSection = styled.article`
  max-width: 750px;

  p {
    font-size: 1.125rem;
    margin: 0;
    line-height: 30px;
    color: ${props => props.theme.palette.text.light};
    padding-bottom: 24px;
    font-weight: 400;
    word-break: break-word;
  }

  b {
    font-size: 1.2rem;
    margin: 0;
    line-height: 30px;
    color: ${props => props.theme.palette.text.vibrant};
    padding-bottom: 24px;
    font-weight: 600;
    word-break: break-word;
  }

  li::marker {
    color: ${props => props.theme.palette.text.vibrant};
  }
`

export const pageQuery = graphql`
  query JobPostBySlug($slug: String!) {
    jobPost: contentfulJobPost(slug: { eq: $slug }) {
      title
      excerpt
      slug
      createdAt(formatString: "MMMM Do, YYYY")
      content {
        raw
      }
    }
  }
`
